<template>
    <div>
        <!-- <slider></slider> -->
    </div>
</template>

<script>
// import Slider from './Slider.vue';

export default {
    data(){
        return {
		}
    },
    components:{
        // Slider,
    }
}

</script>